.dashboardpics .containers-dashboardboxed {
    height: 82%;
    overflow-y: auto;
    justify-content: space-around;
}

.inner-container-dbp {
    width: 280px;
    height: 300px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.inner-container-dbp.img1 {
    background: rgba(232, 235, 255, 1);
}

.inner-container-dbp.img2 {
    background: rgba(220, 246, 233, 1);
}

.inner-container-dbp.img3 {
    background: rgba(251, 241, 216, 1);
}

.inner-container-dbp.img4 {
    background: rgba(232, 235, 255, 1);
}

.img-idp-main {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 85%;
    overflow: hidden;
    margin-top: 5px;
}

.img-idp-main img {
    width: 90%;
    height: 100%;
    object-fit: cover;
}

.dashboardpicsupdated .img-idp-main img {
    width: auto;
    height: auto;
}

.dashboardpics .containers-dashboardboxed::-webkit-scrollbar {
    width: 5px;
}

/* Track */

.dashboardpics .containers-dashboardboxed::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

.dashboardpics .containers-dashboardboxed::-webkit-scrollbar-thumb {
    background: rgb(252, 248, 9);
}

/* Handle on hover */

.dashboardpics .containers-dashboardboxed::-webkit-scrollbar-thumb:hover {
    background: rgb(252, 248, 9);
}


.inner-container-dbp p {
    margin-bottom: 0px;
    text-align: center;
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 10px;
}

@media screen and (max-width: 1050px) {
    .inner-container-dbp {
        margin: 10px;
        height: 280px;
        width: 90%;
        overflow: hidden;
    }

    .inner-container-dbp img {
        height: 79%;
    }

    .container-dpu {
        width: 100% !important;
    }

    .dashboardpicsupdated .title-topoverview {
        padding-left: 0px !important;
    }

    .dashboardpicsupdated .dear-dob {
        padding-left: 0px !important;
    }
}

@media screen and (max-width: 767px) {
    .worker-dpus {
        width: 100% !important;
    }
}

/* Updated stuff */

.dashboardpicsupdated {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
}

.container-dpu {
    width: 80%;
    height: 100%;
    padding: 20px;
    /* overflow-y: auto; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-family: Brandon;
    font-weight: 500;
}

.worker-dpus {
    width: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.container-dpu::-webkit-scrollbar {
    width: 5px;
}

.dashboardpicsupdated .title-topoverview {
    padding-left: 80px;
    font-size: 26px;
    letter-spacing: 0px;
    margin-top: 0px;
}

.dashboardpicsupdated .dear-dob {
    padding-left: 80px;
    font-size: 20px;
}

/* Track */

.container-dpu::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

.container-dpu::-webkit-scrollbar-thumb {
    background: rgb(252, 248, 9);
}

/* Handle on hover */

.container-dpu::-webkit-scrollbar-thumb:hover {
    background: rgb(252, 248, 9);
}