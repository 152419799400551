.dashboardofboxes {
  width: 100%;
  height: 95%;
  /* background-color: black; */
  padding: 0px 100px;
}

.title-topoverview {
  font-size: 28px;
  font-weight: 600;
  margin-top: 10px;
}

.dear-dob {
  color: #adacac;
  margin-bottom: 5px;
}

.containers-dashboardboxed {
  width: 100%;
  height: 92%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
}

.inner-container-db {
  width: 250px;
  height: 140px;
  background-image: url("https://ucarecdn.com/2d2d0d57-adc9-46a7-ba26-d73b6bdedb9f/bgfordivs.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  flex-direction: column;
  margin-bottom: 30px;
  box-shadow: 3px 3px 18px 3px #bab3c2;
}

.inner-container-dbu p {
  color: white;
  margin: 0px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 1.4em;
}

@media screen and (max-width: 1050px) {
  .dashboardofboxes {
    padding: 0px !important;
  }
  .dashboardofboxes .title-topoverview {
    width: 100% !important;
    font-size: 20px !important;
    margin-top: 15px !important;
  }
  .inner-container-db {
    width: 45% !important;
    margin-bottom: 30px !important;
  }
}

.dashboardofboxes .container-dpu {
  width: 100%;
}

.inner-container-dbu {
  width: 250px;
  height: 140px;
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 50px;
  background-image: url("https://ucarecdn.com/0ca192f0-27f0-46b3-b369-77d528df6f26/assessmentBg.png"), linear-gradient(131.58deg, rgba(180, 189, 233, 0.53) 1.34%, #7E8ED9 85.37%);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
}

/* .inner-container-dbu img {
    position: absolute;
    width: 100%;
    height: 101%;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 0px;
} */
