
.workshops-cont {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	gap: 15px;
	margin-top: 30px;
}
.workshop-card {
	min-width: 250px;
	max-width: 270px;
	padding: 15px;
	border-radius: 16px;
	border: 1px solid #878787;
}
.workshop-card-img {
	width: 100%;
	height: 180px;
	object-fit: cover;
	border-radius: 6px;
}
.workshop-card-content {
	margin: 20px 0;
}
.workshop-card-content-topic {
	font-size: 1.3em;
	font-weight: 500;
}
.workshop-card-cta {
	display: flex;
	height: 40px;
	gap: 10px;
}
.workshop-card-cta button {
	outline: none;
	border: 1px solid;
	border-radius: 6px;
	flex: 1;
}
.workshop-card-add {
	background-color: transparent;
}
.workshop-card-join {
	background-color: rgba(55, 158, 144, 1);
	border-color: rgba(55, 158, 144, 1);
	color: #fff;
}

.highlight {
	animation: blinker 1s linear;
  background: #c0eda6;
}
@keyframes blinker {
  80% {
    opacity: 0;
  }
}

.no-events-img {
	width: 40%;
}

@media screen and (max-width: 768px) {
	.no-events-img {
		width: 80%;
	}
}