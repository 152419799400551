.fp-container {
  visibility: visible;
  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f8f8f87d;
  z-index: 1000;
  backdrop-filter: blur(3px);
  transition: all 0.25s ease-in-out,;
  /*background: #000000a4;*/
}
.fp-container-hidden {
  visibility: hidden;
  opacity: 0;
}
.fp-container .fp-loader {
  height: 350px;
  width: 350px;
  left: 50%;
  top: 43%;
  margin-top: -175px;
  margin-left: -175px;
  position: absolute;
  z-index: 1000;
}
