.profile-cont {
  visibility: visible;
  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #394b424d;
  z-index: 1000;
  padding: 15px;
  /* backdrop-filter: blur(3px); */
  transition: all 0.25s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: #000000a4;*/
}

.profile-cont-hidden {
  visibility: hidden;
  opacity: 0;
}

.profile {
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  border-radius: 34px;
  position: relative;
  padding: 20px 20px 10px;
}
.profile-div {
  max-width: 400px;
  padding: 40px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.profile-div label {
  color: #394b42;
  margin-left: 16px;
  font-size: 0.857em;
}
.profile-div input,
.profile-div select {
  border-radius: 32px;
  padding: 8px 16px;
  border: 1px solid #394b42;
  color: #000;
}
.profile-div input:disabled,
.profile-div select:disabled,
.profile-div-input-mobile button:disabled {
  opacity: 0.5;
}
.profile-div-input-cont {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.profile-div-input-cont2 {
  display: flex;
  gap: 5%;
}
.profile-close {
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcf6e9;
  border: 0;
}
.profile-cta {
  align-self: flex-end;
  min-width: 120px;
  padding: 8px 16px;
  border-radius: 32px;
  border: 0;
  color: #fff;
}
.profile-cta-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.profile-div-input-mobile {
  display: flex;
}
.profile-div-input-mobile-cta {
  border: 0;
  background-color: transparent;
  border-radius: 32px 0 0 32px;
  padding: 8px 16px;
  padding-right: 8px;
  border: 1px solid #394b42;
  color: #000;
  border-right-width: 0;
}
.profile-div-input-mobile-cta-cont {
  position: relative;
}
.profile-div-input-mobile-cta-drpdwn {
  width: 100%;
  background-color: white;
  box-shadow: 2px 2px 6px 2px #ececec;
  position: absolute;
  top: 100%;
  border-radius: 8px;
  z-index: 999;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
}
.profile-div-input-mobile-cta-drpdwn button {
  background-color: transparent;
  border: 0;
}
.profile-div-input-mobile input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 8px;
}
.profile-div-input-mobile input::-webkit-outer-spin-button,
.profile-div-input-mobile input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.profile-div-input-age::-webkit-outer-spin-button,
.profile-div-input-age::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}