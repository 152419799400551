.scheduledashboard {
    width: 90%;
}

.scheduledashboard .containers-dashboardboxed {
    display: flex;
    height: 82%;
}

.left-scd {
    width: 100%;
    height: 90%;
    padding: 0 20px 260px 20px;
    font-family: Brandon;
}

.left-scd::-webkit-scrollbar {
    width: 5px;
}

/* Track */

.left-scd::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */

.left-scd::-webkit-scrollbar-thumb {
    background: rgb(252, 248, 9);
}

/* Handle on hover */

.left-scd::-webkit-scrollbar-thumb:hover {
    background: rgb(252, 248, 9);
}

.right-scd {
    width: 38%;
    height: 100%;
}

.main-title-lscd {
    font-size: 1.6em;
    font-weight: 500;
    margin-bottom: 0px;
    font-family: Brandon;
    letter-spacing: 0.5px;
}

.btns-rscd {
    width: 270px;
    /* flex-grow: 1; */
    display: flex;
    align-items: center;
}

.btns-rscd button {
    padding: 12px 15px;
    background-color: white;
    border-radius: 8px;
    border: none;
    outline: none;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: bold;
    margin-left: 40px;
}

.container-lscd {
    width: 850px;
    display: flex;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    background-color: white;
    align-items: center;
    padding-left: 20px;
    border-radius: 8px;
    margin: 30px 0px;
    height: 130px;
    position: relative;
}

.date-clscd {
    width: 70px;
    height: 80px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 1px 8px 3px #d4d4d4;
    background-color: white;
    margin-right: 20px;
}

.date-clscd p {
    margin: 0px;
}

.content-clscd {
    flex-grow: 1;
}

.date-clscd p:first-child {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 5px;
}

.date-clscd p:last-child {
    font-size: 1.2em;
    font-weight: 500;
}

.title-cclscd {
    font-size: 1.3em;
    font-weight: 500;
}

.inline-clscd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.inline-clscd p {
    font-size: 13px;
    letter-spacing: 1px;
}

.btns-workinline {
    display: flex;
    align-items: center;
    padding-right: 25px;
    position: absolute;
    right: 0px;
    bottom: 15px;
}

.btns-workinline p {
    margin: 0px 10px;
    font-size: 1em;
    font-weight: 500;
    display: flex;
    letter-spacing: 0.5px;
    align-items: center;
    cursor: pointer;
}

.box-clscd {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.top-lscd {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.top-sblcb-completed{
	width: 100%;
    height: 60px;
    box-shadow: 2px 2px 6px 2px #ececec;
    display: flex;
    padding: 0px 10px;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
	background-image:linear-gradient(to bottom right,#FA93D2, #895FE0);
	color:#fff;
	font-size:13px;
}

.top-sblcb-completed p{
	padding-bottom:0px;
	margin-bottom:0px;
	width:95%;
}
.booking-action-btns {
    gap: 5px;
}
.completed-header {
    margin-top: 20px;
}
.sessions-month-select {
    justify-content: flex-end;
    max-width: 850px;
    padding: 10px 0;
}
.upcoming-sessions-row, .completed-sessions-row {
    margin: 15px 0 !important;
    width: 100% !important;
    box-shadow: none !important;
    border: 1px solid #878787;
}
.therapist-session-date {
    box-shadow: none !important;
    border-right: 1px solid #000;
    border-radius: 0;
}

@media screen and (max-width: 1280px) {
    .btns-rscd {
        width: 100%;
        justify-content: center;
    }
    .btns-rscd button:first-child {
        margin-left: 0px;
    }
}

.MuiDialog-paperWidthSm {
    max-width: 500px;
}

.MuiDialogContentText-root {
    font-weight: 500 !important;
    color: #8c8c8c !important;
}

.MuiButton-root {
    min-width: 120px !important;
}

.MuiDialogActions-root .MuiButton-root:first-child {
    border: 2px solid gray;
    color: black;
    text-transform: none !important;
    font-weight: 500 !important;
    letter-spacing: 1px;
}

.MuiDialogActions-root .MuiButton-root:nth-child(2) {
    color: white;
    background-color: #069da8;
    text-transform: none !important;
    letter-spacing: 1px;
}

.tooltipclass {
    border-radius: 20px;
}

.select-therapist-recommendations {
    position: absolute;
    top: 200px;
    left: 40px;
}

.title-str {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 25px;
}

.booking-right{
    width:80%;
    display: flex;
    justify-content: space-between;
}
.rating-btn{
    background: #379E90;
    padding: 8px;
    border-radius: 32px;
    border: none;
    outline: none;
    color:white;
}
@media screen and (max-width: 767px) {
    .title-cclscd {
        text-align: center;
    }

    .main-title-lscd {
        text-align: center;
        margin-top: 5px;
    }
}

@media screen and (max-width: 1500px) {
    .container-lscd {
        width: 90%;
    }
    .dashboardofboxes {
        padding: 0px 40px !important;
    }
}

@media screen and (max-width: 1050px) {
    .left-scd {
        width: 100%;
        padding: 0px 5px 260px 5px;
    }
    .right-scd {
        width: 100%;
        margin-bottom: 30px;
        padding-left: 10px;
    }
    .container-lscd {
        width: 100%;
        flex-direction: column;
        height: auto;
        padding: 20px;
        align-items: center !important;
        justify-content: center;
    }
    .inline-clscd {
        width: 100%;
        text-align: center;
        padding: 0px;
    }
    .inline-clscd p {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .btns-workinline {
        position: static;
    }
    .date-clscd {
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .select-therapist-recommendations {
        position: static;
        margin-top: 30px;
        width: 100%;
    }
    .title-str {
        font-size: 14px;
    }
}