/* Global styles */

/* @import "~antd/dist/antd.css"; */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #826eff;
  --secondary: #c2c2c2;
  --body-background: #f3f3f3;
  --shadow: #0001;
  --white: #fff;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  /*place-items: center;*/
  height: 100vh;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}*/

::-webkit-scrollbar {
  width: 5px;
  border-radius: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #F9CE55;
  border-radius: 8px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #F9CE55;
}

@font-face {
  font-family: Brandon;
  src: url("./AssetsUpdated/fonts/Brandon_reg.otf");
  font-display: swap;
}

@font-face {
  font-family: Brandon;
  src: url("./AssetsUpdated/fonts/Brandon_bld.otf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: Brandon;
  src: url("./AssetsUpdated/fonts/Brandon_med.otf");
  font-weight: 450;
  font-display: swap;
}
.fade.modal.show {
  z-index: 99999;
}
.modal-content .modal-header {
  padding: 0;
  border: none;
}
.modal-content .modal-header button.close {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  font-size: 32px;
  padding: 0 10px;
  z-index: 999;
}
.modal-content .modal-header button.close span.sr-only{
  display: none;
}
.user_main button {
  height: 48px;
  width: 121px;
  display: inline-block;
  margin-top: 15px;
  background: #d27474;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 30px;
}
.user_main h1{
  font-size: 50px;
  margin-top: 60px;
}
.user_main textarea {
  height: 100px;
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #aaa3a3;
}

.user_main ul li {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 30px;
  margin: 0 4px 10px;
  font-weight: 600;
      position: relative;
}

.user_main ul {
  padding: 0;
  list-style: none;
}
.user_main ul li .checkcontainer {
  width:100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  right: 0;
}

.user_main ul li .checkcontainer input {
  position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.user_main ul li .radiobtn {
  height: 100%;
  width: 100%;
  display: inline-block;
  border-radius: 30px;
  border: 2px solid #ccc;
}
.user_main ul li .checkcontainer input:checked~.radiobtn::after {
  background: transparent;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 30px;
  margin: auto;
  border: 2px solid #ffb73d;
}

.user_main h6 i {
  color: #FFC107;
  font-size: 22px;
  margin: 0 4px;
}

.user_main h4 {
  font-weight: 600;
}

.user_main .user {
  height: 120px;
  width: 120px;
  background: red;
  border-radius: 50%;
  margin: 20px auto;
}

.user_main h5 {
  font-size: 18px;
}

.user_main {
  max-width: 500px;
  margin: 50px auto;
  text-align: center;
}
section#select_filed {
  max-width: 800px;
  margin: 40px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 6px;
}

section#select_filed select.form-select {
  height: 50px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.16);
  width: 100%;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 600;
}


section#select_filed .box h5 img {
  width: 120px;
  border-radius: 12px;
  position: absolute;
  left: 0;
}

section#select_filed .box h5 {
  position: relative;
  padding-left: 132px;
  font-size: 20px;
  margin: 0;
  height: 76px;
}

section#select_filed .box h5 span {
  display: block;
  font-size: 14px;
  color: #828282;
  font-weight: 500;
  margin-top: 5px;
}

section#select_filed .box {
  border-radius: 12px;
  border: 1.6px solid #E0E0E0;
  background: #e0e0e073;
  padding: 15px;
  margin-top: 22px;
}

section#select_filed .box p {
  font-size: 16px;
  margin-bottom: 0;
  color: #4F4F4F;
}

.date_sec {
  margin-top: 22px;
}

.date_sec input {
  height: 45px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.16);
  width: 95%;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 600;
  color: #4F4F4F;
}

.date_sec label {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
}

.date_sec .form-group {
  width: 100%;
  margin-bottom: 15px;
}

.goal_sec h3 img {
  width: 120px;
  border-radius: 12px;
  position: absolute;
  left: 0;
}

.goal_sec h3 {
  position: relative;
  padding-left: 132px;
  font-size: 20px;
  margin: 0;
  height: 76px;
}

.goal_sec {
  border-radius: 12px;
  border: 1.6px solid #E0E0E0;
  background: #e0e0e073;
  padding: 15px;
  margin-top: 22px;
}

.goal_sec h3 p {
  margin: 5px 0 0;
  color: #828282;
  font-weight: 500;
  font-size: 16px;
}

.goal_sec h3 p span {
  font-weight: 600;
  color: #000;
}

section#select_filed a.btn {
  border-radius: 180px;
  background: linear-gradient(225deg, #FF7F7F 0%, #FF7FB7 98.93%);
  width: 253px;
  height: 48px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  margin: 30px auto 0px;
}

section#survery {
  max-width: 800px;
  margin: 40px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 6px;
}

section#survery select.form-select {
  height: 50px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.16);
  width: 100%;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 600;
}

section#survery label {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
}

section#survery h1 {
  font-size: 22px;
  font-weight: 600;
  margin: 0 0;
}

.questions_sec .form-check {
  margin-bottom: 10px;
}

.questions_sec .form-check label {
  margin-bottom: 0 !important;
  margin-left: 7px;
  font-weight: 400 !important;
  display: inline-block !important;
  vertical-align: super;
}

.questions_sec .form-check input {
  width: 18px;
  height: 18px;
  position: relative;
  top: -1px;
}

.questions_sec {
  background: #e0e0e073;
  padding: 10px;
  margin-top: 16px;
  border-radius: 5px;
}

.questions_sec h3 {
  margin: 5px 0 18px;
  font-size: 18px;
}

section#survery a.btn {
  border-radius: 180px;
  background: linear-gradient(225deg, #FF7F7F 0%, #FF7FB7 98.93%);
  width: 253px;
  height: 48px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  margin: 30px auto 0px;
}

section#step_challenge {
  max-width: 800px;
  margin: 40px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  border-radius: 6px;
}

section#step_challenge select.form-select {
  height: 50px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.16);
  width: 100%;
  padding: 0 15px;
  font-size: 15px;
  font-weight: 600;
}

section#step_challenge h2 {
  font-size: 20px;
}

section#step_challenge .box {
  border-radius: 12px;
  background: #CAE9E4;
  padding: 15px;
}

section#step_challenge .box h3 {
  margin: 0;
  text-align: end;
}

section#step_challenge .box h3 img {
  height: 26px;
  cursor: pointer;
}

section#step_challenge .box h4 img {
  width: 120px;
  border-radius: 12px;
  position: absolute;
  left: 0;
}

section#step_challenge .box h4 {
  position: relative;
  padding-left: 132px;
  font-size: 20px;
  margin: 0;
  height: 90px;
}

section#step_challenge .box h4 span {
  display: block;
  font-size: 14px;
  color: #828282;
  font-weight: 500;
  margin-top: 5px;
}

section#step_challenge .box h5 {
  font-size: 18px;
  margin: 10px 0 8px;
}

section#step_challenge .box h5 span {
  color: #828282;
  margin-left: 8px;
}

section#step_challenge a.btn {
  border-radius: 180px;
  background: linear-gradient(225deg, #FF7F7F 0%, #FF7FB7 98.93%);
  width: 253px;
  height: 48px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  margin: 30px auto 10px;
}

ul.user_main li {
  float: left;
  padding: 4px 20px;
  margin-bottom: 9px;
  margin-right: 8px;
  border-radius: 7px;
  box-shadow: 0px 2px 6px #ccc;
  border: 1px solid #cccccca3;
  font-weight: 500;
  font-size: 14px;
}



ul.user_main {
  list-style: none;
  text-decoration: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
}