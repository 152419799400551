.wholeapp {
  width: 100%;
  /* position: fixed; */
  height: 100%;
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding-left: 30px;
  /* background-color: black; */
  max-width: min(100%, 1820px);
}

body {
  margin: 0px;
  padding: 0px;
  background-color: white !important;
}

.left-menustuff {
  width: 275px;
  height: 80%;
  background-color: rgba(55, 158, 144, 1);
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 4px 18px 5px #c1c1c1;
  position: fixed;
  top: 54%;
  transform: translateY(-50%);
  background-image: url("https://ucarecdn.com/1f4ce15e-e9bc-4c03-8019-94ffaa6fd151/menubackground.png");
  /* background-image: url("https://ucarecdn.com/4abd3201-215e-406f-b8f9-12076b425a76/menubackground.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: Brandon;
}

.title-lms {
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  text-transform: 1px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.content-lms {
  flex-grow: 1;
  margin-top: 10px;
  width: 100%;
  position: relative;
  overflow-y: auto;
}

.content-lms::-webkit-scrollbar {
  display: none;
}

.content-lms ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  overflow-y: auto;
  max-height: 95%;
  padding-bottom: 10px;
  text-align: center;
  max-width: 95%;
}

.content-lms ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 8px;
}

/* Track */

.content-lms ul::-webkit-scrollbar-track {
  background: gray;
  border-radius: 8px;
}

/* Handle */

.content-lms ul::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 8px;
}

/* Handle on hover */

.content-lms ul::-webkit-scrollbar-thumb:hover {
  background: white;
}

.content-lms ul li {
  color: white;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 20px;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.content-lms ul li .MuiSvgIcon-root {
  margin-right: 10px;
}

.content-lms ul li::before {
  position: absolute;
  width: 3px;
  /* height: 100%; */
  left: 0px;
  background-color: white;
  content: " ";
  border-radius: 3px;
  height: 0px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.content-lms ul li:hover::before {
  height: 100%;
}

.content-lms ul li.active::before {
  height: 100%;
}

.top-navbar {
  width: 100%;
  position: fixed;
  top: 0px;
  /* background-color: black; */
  left: 0px;
  padding: 20px 0px;
  z-index: 999999999999;
}

.container-tn {
  /* max-width: 1820px; */
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  margin: 0px auto;
}

.lists-tn {
  margin: 0px 10px;
  position: relative;
}

.lists-tn .MuiSvgIcon-root {
  color: gray;
}

.ring-counts {
  width: 18px;
  height: 18px;
  background-color: #3d40d7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -6px;
  right: -6px;
}

.ring-counts p {
  color: white;
  margin: 0px;
  font-size: 11px;
}

.profile-img {
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 50%;
}

.right-app-stuff {
  flex-grow: 1;
  padding-left: 300px;
  padding-bottom: 80px;
  padding-top: 85px;
  max-width: 100%;
}

.somepaddingbottomras {
  padding-bottom: 120px;
}

.overview .recharts-polar-angle-axis path {
  fill: #f7f9ff;
}

/* .content-lms ul::-webkit-scrollbar {
  display: none;
} */

@media screen and (max-width: 1050px) {
  .wholeapp {
    position: static;
    padding: 0px;
    flex-direction: column;
    /* padding-top: 70px; */
    z-index: 999999999;
  }
  .wholeapp .title-lms {
    display: none;
  }
  .top-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* position: static !important; */
    background-color: white;
  }
  .top-navbar .title-lms {
    color: white;
    margin: 0px;
    margin-left: 20px;
    font-size: 18px;
    padding-top: 30px !important;
  }
  .left-menustuff {
    width: 95%;
    margin: 0px auto;
    display: none;
    top: 0px;
    transform: none;
  }
  .content-lms ul {
    /* display: flex; */
    overflow-x: auto;
  }
  .content-lms {
    margin-top: 0px;
    width: 100%;
  }
  .content-lms ul li {
    padding: 0px 10px;
    white-space: pre;
  }
  /* .right-app-stuff {
        display: none;
    } */
  .content-lms ul li::before {
    width: 0px;
    height: 3px;
    bottom: -10px;
    left: 9%;
  }
  .content-lms ul li.active::before {
    height: 3px;
    width: 90%;
  }
  .container-tn {
    padding-right: 0px;
  }
}

.menu-lists-mobile {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: white;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-lists-mobile .left-menustuff {
  display: flex;
  height: 98%;
  border-radius: 0px;
}

/* .menu-lists-mobile {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: white;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-lists-mobile ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.menu-lists-mobile ul li {
    margin: 10px 0px;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
    position: relative;
}

.menu-lists-mobile ul li .MuiSvgIcon-root {
    margin-right: 15px;
}

.menu-lists-mobile ul li::before {
    width: 0px;
    height: 2px;
    bottom: -10px;
    position: absolute;
    content: " ";
    background-color: black;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.menu-lists-mobile ul li.active::before {
    width: 100%;
} */

/* .c2capp .right-app-stuff {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.b2bapp .dashboardofboxes {
  overflow: hidden;
}

.b2bapp .dashboardofboxes .containers-dashboardboxed::-webkit-scrollbar {
  width: 5px;
}

.b2bapp .dashboardofboxes .containers-dashboardboxed {
  overflow-y: hidden;
  overflow-x: hidden;
}

.dashboardpics .containers-dashboardboxed {
  overflow-y: auto !important;
}

/* Track */

.b2bapp .dashboardofboxes .containers-dashboardboxed::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.b2bapp .dashboardofboxes .containers-dashboardboxed::-webkit-scrollbar-thumb {
  background: rgb(252, 248, 9);
}

/* Handle on hover */

.b2bapp
  .right-app-stuff
  .containers-dashboardboxed::-webkit-scrollbar-thumb:hover {
  background: rgb(252, 248, 9);
}

.whiteobottom {
  position: absolute;
  background-color: white;
  color: black;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  outline: none;
  border-radius: 20px;
  letter-spacing: 1px;
  width: 154px;
  height: 40px;
  font-size: 16px;
}

@media screen and (max-width: 1750px) {
  .whiteobottom {
    margin-top: 20px;
    margin-bottom: 20px;
    position: static;
    transform: none;
  }
}

.headerbookings {
  z-index: 99999;
  width: 100%;
  height: 60px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 2px 2px 12px 2px #89b38c;
  display: flex;
  padding: 0px 40px;
  align-items: center;
  position: relative;
  background-color: white;
  justify-content: space-between;
}

.image-logo-hb {
  max-width: 90px;
}

.lists-header-booking ul {
  display: flex;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.lists-header-booking {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lists-header-booking ul li {
  margin: 0px 20px;
  font-size: 13px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.menu-header-booking {
  display: flex;
  align-items: center;
}

.login-mhb {
  background-color: transparent;
  font-size: 11px;
  font-weight: 500;
  margin-right: 25px;
  border: none;
  outline: none;
}

.gs-mhb {
  width: 100px;
  height: 40px;
  background-color: #3d41d7;
  color: white;
  border-radius: 40px;
  border: none;
  outline: none;
  font-size: 11px;
  box-shadow: 2px 2px 5px 2px #d3f3d7;
}

@media screen and (max-width: 767px) {
  .headerbookings {
    padding: 0px 10px;
    position: static !important;
  }
  .login-mhb {
    margin-right: 10px;
  }
  .headerhomescreen .menu-header-booking .lists-tn {
    margin-right: 0px;
  }
  .inner-notificationboxmain {
    right: -90px !important;
  }
  .notificationsboxreminder {
    right: -120px !important;
  }
}

@media screen and (max-width: 1052px) {
  .lists-header-booking {
    display: none;
  }
}

.notificationsboxcontainer {
  position: relative;
}

.inner-notificationboxmain {
  width: 300px;
  background-color: white;
  position: absolute;
  top: 100%;
  right: 0px;
  height: 350px;
  box-shadow: 2px 2px 12px 2px #d8d8d8;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.notificationsboxreminder {
  height: 270px;
  right: 30px;
}

.top-inbm {
  width: 100%;
  padding: 10px;
  border-bottom: 2px solid black;
}

.top-inbm p {
  margin: 0px;
  letter-spacing: 1px;
  font-weight: 600;
}

.bottom-inbm {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 5px;
  margin-top: 5px;
  overflow-y: auto;
}

.bottom-inbm ol {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.bottom-inbm ol li:hover {
  background-color: #ececec;
  font-size: 17px;
}

.bottom-inbm::-webkit-scrollbar {
  width: 5px;
}

.bottom-inbm::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */

.bottom-inbm::-webkit-scrollbar-thumb {
  background: rgb(252, 248, 9) !important;
}

/* Handle on hover */

.bottom-inbm::-webkit-scrollbar-thumb:hover {
  background: rgb(252, 248, 9) !important;
}

.nonewnotficiationslabel {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
}

.nonewnotficiationslabel .MuiSvgIcon-root {
  margin-right: 10px;
}

.bottom-inbm ol {
  flex-grow: 1;
  align-self: flex-start;
  padding: 5px;
}

.bottom-inbm ul {
  flex-grow: 1;
  align-self: flex-start;
  list-style-type: none;
  margin: 0px;
  padding: 0px 8px;
}

.bottom-inbm ol li,
.bottom-inbm ul li {
  padding: 7px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.successfulpayment .close-ilppa {
  background-image: linear-gradient(131.58deg, #ff8831 1.34%, #fbd7bc 103.94%);
}

.successfulpayment .close-ilppa .MuiSvgIcon-root {
  color: white;
}

.successfulpayment .title-ilppa {
  background: linear-gradient(#ff6c00, #fbd7bc);
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  -webkit-background-clip: text;
}

.dottedspace {
  width: 70%;
  height: 2px;
  border: 1px dashed black;
  margin-top: 20px;
  margin-bottom: 30px;
}

.successfulpayment {
  align-items: center;
  width: 650px !important;
}

.order-details-popup {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.order-details-popup p {
  margin: 0px;
  font-weight: 600;
}

.footer-main {
  width: 100%;
  background-color: #405dc4;
  height: 600px;
}

.top-footermain {
  width: 85%;
  margin: 0px auto;
  height: 500px;
  border-bottom: 1px dashed white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.left-listsmainfooter {
  display: flex;
  flex-wrap: wrap;
}

.container-llmf {
  width: 240px;
  margin-right: 40px;
}

.heading-cllmf {
  font-weight: 500;
  font-size: 20px;
  color: white;
}

.label-cllmf {
  color: white;
  font-size: 13px;
}

.bottom-footermain {
  width: 85%;
  height: 100px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-bfmmain {
  display: flex;
  padding-left: 170px;
  align-items: center;
}

.content-bfmmain p {
  color: white;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.6px;
  margin: 0px;
  margin-right: 90px;
}

.copyrighttext-bfm {
  color: white;
  margin: 0px;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 500;
}

.socials-cbfmain {
  display: flex;
}

.inner-scbf {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid #7a7a7a;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-scbf:last-child {
  margin-right: 0px;
}

.inner-scbf .MuiSvgIcon-root {
  font-size: 16px;
}

.newsletter-mainfooter {
  width: 338px;
  height: 251px;
  background-color: #f9fafd;
  border-radius: 20px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title-nlmainfooter {
  font-weight: 500;
}

.desc-nlmainfooter {
  font-size: 13px;
  color: #0a142f;
  margin-bottom: 0px;
}

.input-newslettermain {
  width: 100%;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
  background-color: white;
  margin-bottom: 20px;
}

.input-newslettermain input {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0px 10px;
  flex-grow: 1;
  max-width: 210px;
}

.arrow-inlm {
  width: 50px;
  height: 50px;
  background-color: #405dc4;
  opacity: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-inlm .MuiSvgIcon-root {
  color: white;
}

@media screen and (max-width:767px){
  .successfulpayment{
    width:100% !important;
  }
}